import { gql } from 'graphql-request';

const areaQuery = gql`
  query ($id: ID!, $subdomain: String!) {
    area(id: $id, subdomain: $subdomain) {
      cityId
      id
      lat
      lng
      titleAr
      titleEn
      deliveryZone {
        id
        assignedAreasBasedOnDistance
        branchId
        countryId
        deliveryFee
        deliveryTime
        minimumOrder
        openingHours
        originalDeliveryTime
      }
    }
  }
`;

export default areaQuery;
