export const URLS = {
  HOME: '/',
  SEARCH: '/search',
  BRANCHES: '/branches',
  BRANCHES_DETAILS: '/branches/[id]',
  ORDER_MODE: '/order-mode',
  ORDER_FAST_ACCOUNT: '/orderfast-account',
  WALLET_TRANSACTIONS: '/wallet-transactions',
  WALLET_TRANSACTIONS_DETAILS: '/wallet-transactions/[id]',
  TRANSACTION_DETAILS: '/transaction-details',
  ORDER_DELIVERY: '/order/delivery',
  ESTIMATED_TIME: '/estimated-time',
  PRODUCTS: '/products',
  CATEGORY_PRODUCTS: '/categories/[id]',
  PRODUCT_DETAILS: '/products/[id]',
  SHOPPING_CART: '/shopping-cart',
  CHECKOUT: '/checkout',
  STATUS: '/status/[id]',
  ORDER_UPDATES: '/updates/[id]',
  ADDRESS_BOOK: '/user/address-book',
  NEW_ADDRESS: '/user/address',
  EDIT_ADDRESS: '/user/address/[id]',
  CAR_BOOK: '/user/car-book',
  NEW_CAR: '/user/car',
  CAR_DETAILS: '/user/car/[id]',
  ADDRESS_MAP: '/user/address-map',
  AREAS_LIST: '/user/areas-list',
  GIFT_RECIPIENT: '/gift-recipient',
  SENT_CART_LINK: '/sent-cart-link',
  NEW_UMBRELLA: '/user/umbrella',
  USER_PROFILE: '/user/profile',
};
