import Color from 'color';
import ColorString from 'color-string';

import getStoreColors from './colorsUtils';
import { paletteTypes } from './themeTypes';

const theme = (palette: paletteTypes, currentDirection: string): any => {
  const primaryColor = getStoreColors(palette);
  const alpha10 = 0.1 / Color(primaryColor).alpha();
  const primary10 = ColorString.to.rgb(Color(primaryColor).alpha(alpha10).rgb().array());
  const alpha20 = 0.2 / Color(primaryColor).alpha();
  const primary20 = ColorString.to.rgb(Color(primaryColor).alpha(alpha20).rgb().array());
  const alpha40 = 0.4 / Color(primaryColor).alpha();
  const primary40 = ColorString.to.rgb(Color(primaryColor).alpha(alpha40).rgb().array());
  const generatePrimaryColorOpacities = {
    primary10,
    primary20,
    primary40
  };

  return {
    direction: currentDirection,
    color: {
      primary: ColorString.to.hex(Color(primaryColor).rgb().array()),
      ...generatePrimaryColorOpacities,
      danger: '#AA3737',
      success: '#00661A',
      warning: '#f9a825',
      headlineText: '#212121',
      disabled: '#767676',
      bodyText: '#616161',
      border: '#e0e0e0',
      background: '#f5f5f5',
      white: 'white',
      black: 'black',
      transparent: 'transparent',
      greyShade: '#cccccc',
      grey0: '#585858',
      grey1: '#f5f5f5',
      grey2: '#e0e0e0',
      grey3: '#EFEFEF',
      grey4: '#1f1f1f0d',
      lightYellow: '#edb6001a',
      iconBackground: ColorString.to.rgb(Color(primaryColor).alpha(0.15).rgb().array()),
      link: '#1352BE',
      overlayBackground: ColorString.to.rgb(Color('black').alpha(0.8).rgb().array()),
      alphaWarning: '#EDB6001A',
      alphaSuccess: '#37995D1A',
      alphaDanger: '#E048481A',
      alphaLink: '#0C95EB1A',
      alphaGrey0: '#7d7d7d80',
      alphaBlack: '#1F1F1FBF',
      alphaBackground: '#1F1F1F1F',
      blueAccent: '#1354C2',
    },
    fontFamily: 'inter, sans-serif',
    fontSizes: {
      largeTitle: ['24px', '32px', '48px'],
      title: ['20px', '24px', '32px'],
      header: ['18px', '20px', '24px'],
      subHeader: '18px',
      headlineBody: '16px',
      body1: '16px',
      body2: '14px',
      caption: '12px',
      smallText: '14px',
    },
    fontWeights: {
      bold: 700,
      semiBold: 600,
      medium: 500,
      regular: 400,
    },
    screenBreakpoints: {
      smallMobile: '360px',
      mobile: '768px',
      tablet: '992px',
      // TODO: REMOVE BREAKPOINTS BEFORE THIS LINE
      small: '576px',
      medium: '768px',
      large: '992px',
      extraLarge: '1200px',
      extraExtraLarge: '1400px',
    },
    space: [0, 4, 8, 12, 16, 20, 24, 28, 32, 36, 40, 44, 48, 52, 56, 60, 64, 68, 72, 76],
  };
};

export default theme;
