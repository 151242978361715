import { FULFILLMENT_MODES } from 'common/constants';
import { DeliveryZoneMs } from 'service/types';
import { AddressWithDeliveryZone, CoordsType } from '../../../common/types';

const { asin, cos, sin, sqrt, PI } = Math;

export const getClosestAddress = (
  addresses: Array<AddressWithDeliveryZone>,
  curCoords: CoordsType,
  orderMode: string,
  persistedSupportedDzms?: DeliveryZoneMs,
): AddressWithDeliveryZone => {
  if (orderMode !== FULFILLMENT_MODES.DELIVERY || !curCoords || !persistedSupportedDzms) return null;

  for (let i = 0; i < addresses?.length; i++) {
    const address = addresses[i];
    if (
      address.lat &&
      address.lng &&
      haversineDistance({ lat: Number(address.lat), lng: Number(address.lng) }, curCoords) <= 100 &&
      address?.deliveryZone?.id === persistedSupportedDzms?.id
    )
      return address;
  }

  return null;
};

// All the below code is taken from https://www.npmjs.com/package/haversine-distance
const R = 6378137;

const squared = x => x * x;

const toRad = x => (x * PI) / 180.0;

const hav = x => squared(sin(x / 2));

// get distance between two coordinates in meters
const haversineDistance = (a: CoordsType, b: CoordsType) => {
  const aLat = toRad(a.lat);
  const bLat = toRad(b.lat);
  const aLng = toRad(a.lng);
  const bLng = toRad(b.lng);

  const ht = hav(bLat - aLat) + cos(aLat) * cos(bLat) * hav(bLng - aLng);
  return 2 * R * asin(sqrt(ht));
};
