import * as Sentry from '@sentry/nextjs';

const externalLogger = {
  async log({ error, extraData }) {
    const { requestSchema, ...rest } = extraData || {};
    Sentry.captureException(error, extraData ? { extra: rest, fingerprint: [requestSchema] } : {});
    await Sentry.flush(2000);
  },
};

const LoggerLevels = {
  error({ error, extraData }: { error: any; extraData: any }) {
    externalLogger.log({ error, extraData });
  },
};

const logger = {
  ...LoggerLevels,
};

export default logger;
