import React, { useEffect, useState } from 'react';

import { fulfillmentSettingsContext } from './context';
import { usePersistedAreaId, usePersistedBranchId, usePersistedOrderMode, useRemoveLocaleStorage } from '../../common/hooks';
import { useAreaDetails } from '../../service/hooks/area';
import { Area, Branch } from '../../service/types';
import { useBranchDetails } from '../../service/hooks';
import ServiceConfigs from '../../service/config';

const FulfillmentSettingsProvider = ({ children }) => {
  // states
  const [areaDetails, setAreaDetails] = useState<Area>();
  const [branchDetails, setBranchDetails] = useState<Branch>();

  // persisted data
  const removeLocaleStorage = useRemoveLocaleStorage();
  const [persistedAreaId, setPersistedAreaId] = usePersistedAreaId();
  const [persistedBranchId, setPersistedBranchId] = usePersistedBranchId();
  const [orderMode, setOrderMode] = usePersistedOrderMode();
  const areaId = persistedAreaId?.id;
  const isDZMS = ServiceConfigs.getUseDeliveryzonesMs();

  // loading state mutate data
  const [isLoading, setIsLoading] = useState(true);

  const clearOrderSettings = async () => {
    await removeLocaleStorage();
    setAreaDetails(null);
    setBranchDetails(null);
  };

  const {
    data: areaData,
    isLoading: isAreaLoading,
    mutate: mutateArea,
  } = useAreaDetails({
    id: areaId,
    onComplete: async response => {
      // area is not covered or the store migrated to new DZMS
      if (!response.deliveryZone || (response?.id && isDZMS)) {
        await clearOrderSettings();
      }
    },
  });

  const {
    data: branchData,
    isLoading: isBranchLoading,
    mutate: mutateBranch,
  } = useBranchDetails({
    branchId: persistedBranchId,
    onComplete: async branchResponse => {
      if (!branchResponse?.branch) {
        await clearOrderSettings();
      }
    },
  });

  useEffect(() => {
    setIsLoading(isAreaLoading || isBranchLoading);
  }, [isAreaLoading, isBranchLoading]);

  useEffect(() => {
    if (areaData && areaData.deliveryZone) {
      setAreaDetails(areaData);
    }
  }, [areaData?.id]);

  useEffect(() => {
    if (branchData?.branch) {
      setBranchDetails(branchData?.branch);
    }
  }, [branchData?.branch?.id]);

  return (
    <fulfillmentSettingsContext.Provider
      value={{
        area: areaDetails,
        isAreaLoading,
        mutateArea,
        setAreaId: setPersistedAreaId,
        branch: branchDetails,
        isBranchLoading: isLoading,
        mutateBranch,
        setBranchId: setPersistedBranchId,
        orderMode,
        setOrderMode,
        isLoading,
      }}
    >
      {children}
    </fulfillmentSettingsContext.Provider>
  );
};

export default FulfillmentSettingsProvider;
