import { gql } from 'graphql-request';

const countryDetailsQuery = gql`
  query ($id: ID!) {
    country(id: $id) {
      code
      currency
      id
      timeZone
      lat
      lng
    }
  }
`;

export default countryDetailsQuery;
