import { bulkCheckSupportedZone } from 'common/utils';
import { AddressBookWithDeliveryZone } from 'common/types';
import { usePersistedCountryDetails } from 'common/hooks';
import { COUNTRY_CODES } from 'common/constants';
import { useQuery } from '../../utils';
import { QueryHookResponse } from '../../types/internal';
import { addressBookQuery } from '../../queries';
import ServiceConfigs from '../../config';
import { useBulkCheckSupportedZone } from '../deliveryZones';

type useAddressBookType = ({
  areaId,
  shouldQuery,
}?: {
  areaId?: string;
  shouldQuery?: boolean;
}) => QueryHookResponse<AddressBookWithDeliveryZone>;

const useAddressBook: useAddressBookType = ({ areaId, shouldQuery = true } = {}) => {
  const authHeaders = ServiceConfigs.getAuthorizationHeader();
  const useDeliveryzonesMs = ServiceConfigs?.getUseDeliveryzonesMs();
  const storeId = ServiceConfigs?.getStoreId();
  const bulkCheckSupportedZoneCallBack = useBulkCheckSupportedZone();
  const [persistedCountryDetails] = usePersistedCountryDetails();

  return useQuery<AddressBookWithDeliveryZone>({
    query: addressBookQuery,
    variables:
      shouldQuery && authHeaders
        ? { subdomain: ServiceConfigs.getSubDomain(), areaId: areaId ? parseInt(areaId) : null }
        : null,
    requestHeaders: { ...authHeaders },
    handler: (res: any) => {
      let checkedAddresses = [];
      const waitForAddresses = async () => {
        const shouldHideUnverified = useDeliveryzonesMs && persistedCountryDetails?.code === COUNTRY_CODES.KW;
        checkedAddresses = await bulkCheckSupportedZone(
          res?.addressBook?.addresses,
          bulkCheckSupportedZoneCallBack,
          storeId,
          shouldHideUnverified,
        );
        return { ...res?.addressBook, addresses: checkedAddresses };
      };
      if (useDeliveryzonesMs) {
        return waitForAddresses();
      }
      return {
        ...res?.addressBook,
        addresses: res?.addressBook?.addresses?.sort(address => (address.covered ? -1 : 1)),
      };
    },
    config: {},
  });
};

export default useAddressBook;
