export const FULFILLMENT_MODES = {
  DELIVERY: 'delivery',
  PICKUP: 'pickup',
  CAR_PICKUP: 'carPickup',
  DELIVERY_AND_PICKUP: 'delivery_and_pickup',
  BEACH: 'beach',
};

export enum STORE_STATUS {
  closed = 'storeClosed',
  busy = 'storeBusy',
}

export const STORE_ORIGIN = 'storefront';
