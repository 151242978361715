import { gql } from 'graphql-request';

const bulkCheckSupportedZoneQuery = gql`
  query ($addresses: [BulkSupportedDeliveryZoneRequest!]!) {
    bulkSupportedDeliveryZones(addresses: $addresses) {
      addressId
      deliveryZone {
        id
        geoShape
        branchReferenceId
        zoneName
        deliveryFee
        deliveryTime
        countryId
        minimumOrder
        restaurantReferenceId
        openingHours
        posExternalId
      }
    }
  }
`;

export default bulkCheckSupportedZoneQuery;
