import { DIRECTIONS, LANGUAGE_AR } from 'common/constants';
/**
 * Localizes the given number according the given language.
 *
 * @param lang
 * @param number
 *
 * @returns Localized number.
 */
export const localizeNumber: (lang: string, number: number | string) => string = (lang, number) => {
  switch (lang.toLowerCase()) {
    case LANGUAGE_AR:
      return Number(number).toLocaleString('ar-EG');
    default:
      return Number(number).toLocaleString('en-US');
  }
};

type LocalizeCurrency = (lang: string, value: { amount: number; currency: string }) => string;

/**
 * Localizes the given amount and currency according to the given language.
 *
 * @param lang
 * @param value
 * @param value.amount
 * @param value.currency
 *
 * @returns Localized currency.
 */
export const localizeCurrency: LocalizeCurrency = (lang, { amount, currency }) => {
  let locale;
  if (lang === LANGUAGE_AR) {
    locale = 'ar-EG';
  } else {
    locale = 'en-US';
  }

  return Number(amount).toLocaleString(locale, { style: 'currency', currency });
};

/**
 * Returns direction based on given language.
 *
 * @param language
 *
 * @returns Content direction.
 */
export const getLanguageDirection: (language: string) => string = language => {
  if (language === LANGUAGE_AR) {
    return DIRECTIONS.RIGHT_TO_LEFT;
  }
  return DIRECTIONS.LEFT_TO_RIGHT;
};
