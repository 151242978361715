import { CLIENT_TYPE } from 'common/constants';
import {
  QueryBulkSupportedDeliveryZonesArgs,
  BulkSupportedDeliveryZonesResponse,
} from '../../types/generated/deliveryzones_ms_index';
import { apiMutate } from '../../utils/mutation';
import { bulkCheckSupportedZoneQuery } from '../../queries/deliveryZones';

export type UseBulkCheckSupportedZoneCallbackType = () => (
  input: QueryBulkSupportedDeliveryZonesArgs,
) => Promise<void | Partial<BulkSupportedDeliveryZonesResponse> | { hasError: boolean }>;

const useBulkCheckSupportedZone: UseBulkCheckSupportedZoneCallbackType = () => variables =>
  apiMutate({
    mutation: bulkCheckSupportedZoneQuery,
    variables: { ...variables },
    clientType: CLIENT_TYPE.DZMS_CLIENT,
    handler: (response: any) => response.bulkSupportedDeliveryZones,
  });

export default useBulkCheckSupportedZone;
