import { storeLayoutQuery } from '../queries';
import ServiceConfigs from '../config';
import { Store } from '../types/generated';
import { fetcher, stringify } from '../utils';

export const fetchStoreLayoutInfo = async (): Promise<Store> =>
  fetcher({
    query: storeLayoutQuery,
    variables: stringify({ subdomain: ServiceConfigs.getSubDomain() }),
    handler: (response: any) => response.store,
    retryCount: 3,
  });
