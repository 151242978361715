import useAuthorizeCart from 'service/hooks/cart/useAuthorizeCart';

import { usePersistedBranchId, usePersistedCartData, usePersistedOrderMode } from '../../../../common/hooks/persistent';

const useAuthorizeCartIfExists = () => {
  const [mode] = usePersistedOrderMode();
  const [cartData, setCartData, removeCartData] = usePersistedCartData();
  const [branchId] = usePersistedBranchId();
  const authorizeCart = useAuthorizeCart();

  return async () => {
    if (branchId && mode && cartData?.cartId) {
      const isAuthorized = await authorizeCart({ cartId: cartData.cartId });
      if (isAuthorized) {
        setCartData({ ...cartData, isCartAuthorized: true });
      } else {
        removeCartData();
      }
    }
  };
};

export default useAuthorizeCartIfExists;
