import { ErrorHandler } from 'common/lib/errorHandler';
import { CLIENT_TYPE } from 'common/constants';
import { Handler } from '../types/internal';
import { stringify } from './misc';
import { fetcher } from './fetch';

type MutateParams<Data> = {
  mutation: string;
  variables: unknown;
  clientType?: string;
  handler?: Handler<Data>;
  initialData?: Data;
  clientName?: string;
  requestHeaders?: any;
};
// TODO check if we need default value for requestHeaders
export async function apiMutate<Data>({
  mutation,
  variables,
  clientType = CLIENT_TYPE.CLIENT,
  handler,
  requestHeaders,
}: MutateParams<Data>): Promise<Data> {
  let response;
  try {
    response = await fetcher({
      query: mutation,
      variables: stringify(variables),
      clientType,
      handler,
      requestHeaders,
    });
  } catch (error) {
    const err = JSON.stringify({ ...error, requestSchema: mutation, variables }, undefined, 2);
    ErrorHandler(JSON.parse(err));
  }

  return response;
}
