import { prefixLocalStorageKey } from './orderfast/prefixLocalStorageKey';

class SessionStorage {
  static getProductDetails = () => {
    const productDetailsKey = prefixLocalStorageKey('product-details');

    const productDetails = sessionStorage.getItem(productDetailsKey);

    return productDetails ? JSON.parse(productDetails) : null;
  };

  static setProductDetails = productDetails => {
    const productDetailsKey = prefixLocalStorageKey('product-details');

    sessionStorage.setItem(productDetailsKey, JSON.stringify(productDetails));
  };

  static removeProductDetails = () => {
    const productDetailsKey = prefixLocalStorageKey('product-details');

    sessionStorage.removeItem(productDetailsKey);
  };

  static getOrderNumber = () => {
    const orderNumberKey = prefixLocalStorageKey('order-number');

    const orderNumber = sessionStorage.getItem(orderNumberKey);

    return orderNumber ? JSON.parse(orderNumber) : null;
  };

  static setOrderNumber = orderNumber => {
    const orderNumberKey = prefixLocalStorageKey('order-number');

    sessionStorage.setItem(orderNumberKey, JSON.stringify(orderNumber));
  };

  static removeOrderNumber = () => {
    const orderNumberKey = prefixLocalStorageKey('order-number');

    sessionStorage.removeItem(orderNumberKey);
  };
}

export { SessionStorage };
