import { ErrorCodes } from '../enums';
import { IErrorDetails } from '../types';
import basicErrorsSchema from './basicErrorsSchema';

interface IErrorsFactory {
  route: string | null;
  constructErrorsFactory: (route: string) => void;
  getError: (errorKey: ErrorCodes) => IErrorDetails;
}

const ErrorsFactory: IErrorsFactory = {
  route: null,

  constructErrorsFactory(route) {
    this.route = route;
  },

  getError(errorKey) {
    if (!errorKey) {
      return null;
    }
    const basicError = basicErrorsSchema.get(errorKey);
    const error = {
      ...basicError,
    };
    return error;
  },
};

export default ErrorsFactory;
