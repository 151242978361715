import { NextPageContext } from 'next';
import { AppContext } from 'next/app';

export interface ContextRegistryType {
  context: AppContext | null;
  setContext: (context: AppContext) => void;
  getAppContext: () => AppContext | null;
  getPageContext: () => NextPageContext | null;
}

/**
 * A resister for the app context or page context.
 */
const ContextRegistry: ContextRegistryType = {
  context: null,

  setContext(context: AppContext): void {
    this.context = context;
  },

  getAppContext(): AppContext | null {
    return this.context;
  },

  getPageContext(): NextPageContext | null {
    return (this.context as AppContext)?.ctx;
  },
};

export default ContextRegistry;
