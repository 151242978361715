export interface ServiceConfigsType {
  subdomain?: string;
  userToken?: string;
  orderFastStore?: string;
  useDeliveryzonesMs?: boolean;
  multicountryEnabled?: boolean;
  googleMapsApiKey?: string;
  storeId?: string;
  privateKey?: string;
  init: ({
    subdomain,
    userToken,
    orderFastStore,
    useDeliveryzonesMs,
    multicountryEnabled,
    googleMapsApiKey,
    storeId,
    privateKey,
  }: {
    subdomain: string;
    userToken?: string;
    orderFastStore?: string;
    useDeliveryzonesMs?: boolean;
    multicountryEnabled?: boolean;
    googleMapsApiKey?: string;
    storeId?: string;
    privateKey?: string;
  }) => void;
  setSubDomain: (subdomain: string) => void;
  getSubDomain: () => string | null;
  getPrivateKey: () => string | null;
  setUserToken: (userToken: string) => void;
  getUserToken: () => string | null;
  getAuthorizationHeader: () => Record<string, string> | null;
  getOrderFastStore: () => string | null;
  setUseDeliveryzonesMs: (useDeliveryzonesMs: boolean) => void;
  getUseDeliveryzonesMs: () => boolean | null;
  getMulticountryEnabled: () => boolean | null;
  setStoreId: (storeId: string) => void;
  getStoreId: () => string | null;
  getGoogleMapsApiKey: () => string | null;
}

const ServiceConfigs: ServiceConfigsType = {
  subdomain: null,
  userToken: null,
  storeId: null,
  useDeliveryzonesMs: false,
  multicountryEnabled: false,
  privateKey: null,
  googleMapsApiKey: null,
  init({
    subdomain,
    userToken,
    orderFastStore,
    useDeliveryzonesMs,
    multicountryEnabled,
    googleMapsApiKey,
    storeId,
    privateKey,
  }: {
    subdomain: string;
    userToken?: string;
    orderFastStore: string | null;
    useDeliveryzonesMs?: boolean;
    multicountryEnabled?: boolean;
    googleMapsApiKey?: string;
    storeId?: string;
    privateKey?: string;
    logName?: string;
  }): void {
    this.subdomain = subdomain;
    this.userToken = userToken ? `Bearer ${userToken}` : userToken;
    this.orderFastStore = orderFastStore;
    this.useDeliveryzonesMs = useDeliveryzonesMs === undefined ? this.useDeliveryzonesMs : useDeliveryzonesMs;
    this.multicountryEnabled = multicountryEnabled === undefined ? this.multicountryEnabled : multicountryEnabled;
    this.googleMapsApiKey = googleMapsApiKey ?? this.googleMapsApiKey;
    this.storeId = storeId || this.storeId;
    if (privateKey) this.privateKey = privateKey;
  },

  setSubDomain(subdomain: string): void {
    this.subdomain = subdomain;
  },

  getSubDomain(): string | null {
    return this.subdomain;
  },

  setUserToken(userToken: string): void {
    if (userToken) this.userToken = `Bearer ${userToken}`;
  },

  getUserToken(): string | null {
    return this.userToken;
  },

  getAuthorizationHeader() {
    return this?.userToken ? { authorization: this.userToken } : null;
  },

  getPrivateKey() {
    return this.privateKey ?? this.privateKey;
  },

  getOrderFastStore(): string {
    return this.orderFastStore;
  },

  setUseDeliveryzonesMs(useDeliveryzonesMs: boolean): void {
    this.useDeliveryzonesMs = useDeliveryzonesMs;
  },

  getUseDeliveryzonesMs(): boolean {
    return this.useDeliveryzonesMs;
  },

  getMulticountryEnabled(): boolean {
    return this.multicountryEnabled;
  },

  getGoogleMapsApiKey(): string | null {
    return this.googleMapsApiKey;
  },

  setStoreId(storeId: string): void {
    this.storeId = storeId;
  },

  getStoreId(): string {
    return this.storeId;
  },
};

export default ServiceConfigs;
