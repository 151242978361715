import { CustomActionsEnum } from 'common/enums';
import { NextRouter } from 'next/router';
import ServiceConfigs from '../../service/config';
import { CustomActionsRegistry } from '../lib/registries';
import { redirectToOrderFast, OrderFastQueryParams } from './redirectToOrderFast';

let orderFastParams: OrderFastQueryParams = {
  path: '',
  locale: '',
};

export const setRedirectToOrderFastCustomAction = ({
  router,
  countryCode,
}: {
  router: NextRouter;
  countryCode: string;
}): void => {
  const userToken = ServiceConfigs.getUserToken();
  orderFastParams = {
    path: router.asPath,
    locale: router.locale,
    countryCode,
    forceLogin: !!userToken,
  };
  CustomActionsRegistry.setCustomAction({
    key: CustomActionsEnum.RedirectToOrderFast,
    fn: () => redirectToOrderFast(orderFastParams),
  });
};

export const updateRedirectToOrderFastCustomActionRoute = (router: NextRouter): void => {
  orderFastParams = {
    ...orderFastParams,
    path: router.asPath,
    locale: router.locale,
  };
  CustomActionsRegistry.setCustomAction({
    key: CustomActionsEnum.RedirectToOrderFast,
    fn: () => redirectToOrderFast(orderFastParams),
  });
};
