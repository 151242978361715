/* eslint-disable no-console */
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import {
  usePersistedCountryDetails,
  usePersistedUserAddressId,
  usePersistedFulfillmentTimeAndType,
  usePersistedCartData,
  usePersistedUserCarId,
  usePersistedSelectedCoordinates,
  usePersistedSupportedDeliveryZone,
  usePersistedOrderMode,
  usePersistedBranchId,
  usePersistedAreaId,
  usePersistedOperatorId,
} from '.';
import { replaceRoute } from '../utils/orderfast/routing';
import { useFetchPaymentLinkData } from '../../service/hooks';

const useSettingPaymentLinkPersistedStates = (referenceId: string) => {
  const [persistedCountryDetails, setPersistedCountryDetails] = usePersistedCountryDetails();

  const [, setPersistedUserAddressId] = usePersistedUserAddressId();
  const [, setPersistedFulfillmentTimeAndType] = usePersistedFulfillmentTimeAndType();
  const [, setPersistedCartData] = usePersistedCartData();
  const [, setPersistedUserCarId] = usePersistedUserCarId();
  const [, setOrderMode] = usePersistedOrderMode();
  const [, setBranchId] = usePersistedBranchId();
  const [, setAreaId] = usePersistedAreaId();
  const [, setPersistedOperatorId] = usePersistedOperatorId();

  const [, setSelectedCoordinates] = usePersistedSelectedCoordinates();
  const [, setSupportedDeliveryZone] = usePersistedSupportedDeliveryZone();

  const { data: fetchedPaymentLinkData, isLoading: isLoadingFetchingPaymentLinkData } = useFetchPaymentLinkData({
    referenceId,
  });
  const router = useRouter();

  useEffect(() => {
    const removeReferenceIdFromQuery = async () => {
      const { referenceId: referenceIdQueryParam, ...queryWithoutReferenceId } = router.query;
      await replaceRoute(
        { pathname: router.pathname, query: queryWithoutReferenceId },
        { locale: router.locale, shallow: true },
      );
    };

    if (fetchedPaymentLinkData && !isLoadingFetchingPaymentLinkData) {
      const {
        selectedUserAddressId,
        countryDetails,
        selectedOrderMode,
        selectedArea,
        branchId,
        fulfillmentTimeType,
        cartData,
        operatorId,
        selectedUserCarId,
        selectedCoordinates,
        supportedDeliveryZone,
      } = fetchedPaymentLinkData?.data;
      setPersistedOperatorId(operatorId);
      setPersistedCartData(cartData);
      setPersistedCountryDetails({ ...countryDetails, currency: persistedCountryDetails?.currency });
      setPersistedUserAddressId(String(selectedUserAddressId));
      setOrderMode(selectedOrderMode);
      if (selectedArea) setAreaId({ id: selectedArea?.id });
      if (selectedCoordinates) setSelectedCoordinates(selectedCoordinates);
      if (supportedDeliveryZone) setSupportedDeliveryZone(supportedDeliveryZone);
      if (selectedUserCarId) setPersistedUserCarId(selectedUserCarId);
      setBranchId(String(branchId));
      setPersistedFulfillmentTimeAndType(fulfillmentTimeType);

      removeReferenceIdFromQuery();
    }
  }, [fetchedPaymentLinkData]);
};

export default useSettingPaymentLinkPersistedStates;
