import { getCurrentBaseUrl } from 'common/utils';
import config from 'common/config';
import ServiceConfigs from 'service/config';

export type OrderFastQueryParams = {
  path: string;
  locale: string;
  countryCode?: string;
  forceLogin?: boolean;
};

export const redirectToOrderFast = (queryParams: OrderFastQueryParams): void => {
  const { path, locale, countryCode, forceLogin = false } = queryParams;
  const baseUrl = getCurrentBaseUrl();
  const subdomain = ServiceConfigs.getSubDomain();
  let orderFastUrl = `${
    config.common.orderFastUrl
  }${locale}/phone-number?redirectionUrl=${baseUrl}/${locale}${path}&countryCode=${countryCode}&subdomain=${subdomain}&cachedFallbackUrl=${cachedFallBackUrl(
    baseUrl,
    locale,
  )}`;
  orderFastUrl = forceLogin ? `${orderFastUrl}&forceLogin=true` : orderFastUrl;
  if (typeof window !== typeof undefined) window.location.replace(orderFastUrl);
};

// the base url of the store will be cached on orderfast incase the user goes back without a redirect url.
const cachedFallBackUrl: (baseUrl: string, locale: string) => string = (baseUrl, locale) => {
  const orderFastStore = ServiceConfigs.getOrderFastStore();
  if (!orderFastStore) {
    return `${baseUrl}/${locale}`;
  }
  return `${baseUrl}/${locale}/${orderFastStore}`;
};
