import { gql } from 'graphql-request';

const branchDetailsQuery = gql`
  query ($subdomain: String!, $branchId: ID!) {
    store(subdomain: $subdomain) {
      id
      branch(id: $branchId) {
        id
        titleAr
        titleEn
        addressAr
        addressEn
        areaEn
        busyUntil
        contactNumber
        phoneNumber
        delivery
        deliveryOrdersEnabled
        beachOrderEnabled
        dineIn
        gmapsUrl
        lat
        lng
        openingHours
        pickupEnabled
        pickupOrdersType
        published
        maxOrderFulfillmentPeriod
        busyMode
        busyFrom
        busyUntil
      }
    }
  }
`;

export default branchDetailsQuery;
