import { Area, Scalars } from '../../types/generated';
import { useQuery } from '../../utils';
import { QueryHookResponse } from '../../types/internal';
import { areaQuery } from '../../queries/area';
import ServiceConfigs from '../../config';

type UseAreaDetailsType = ({
  id,
  onComplete,
}: {
  id?: Scalars['ID'];
  onComplete?: (res: any) => void;
}) => QueryHookResponse<Area>;

const useAreaDetails: UseAreaDetailsType = ({ id, onComplete }) =>
  useQuery<Area>({
    query: areaQuery,
    variables: id ? { id, subdomain: ServiceConfigs?.getSubDomain() } : null,
    handler: (res: any) => {
      onComplete?.(res?.area);
      return res?.area;
    },
    config: { revalidateOnMount: true },
  });

export default useAreaDetails;
