export enum ErrorHandlers {
  Modal = 'modal',
  SnackBar = 'snackBar',
  onErrorAction = 'onErrorAction',
}

export enum ErrorCodes {
  // Cart Items Errors
  CartItemUnavailableOptionQuantityErrorCode = 'CI101',
  CartItemOptionUnavailableInBranchErrorCode = 'CI102',
  CartItemInsufficientStockErrorCode = 'CI103',
  CartItemFulfillmentModeNotValidErrorCode = 'CI104',
  CartItemItemUnavailableErrorCode = 'CI105',
  CartItemNotAuthorizedErrorCode = 'CI106',

  // Menu Items errors
  MenuItemNotPublishedInBranchErrorCode = 'MI404',

  // Checkout Errors
  CheckOutGenerateOrderNumberErrorCode = 'CO101',
  CheckOutOrderItemUnavailableErrorCode = 'CO102',
  CheckOutInsufficientStockForOrderErrorCode = 'CO103',
  CheckOutFulfillmentModeNotValidForOrderErrorCode = 'CO104',
  CheckOutOptionUnavailableInOrderBranchErrorCode = 'CO105',
  CheckOutUnavailableOptionQuantityForOrderErrorCode = 'CO106',
  CheckOutInvalidPaymentMethodErrorCode = 'CO107',
  CheckOutStoreNotPublishedErrorCode = 'CO108',
  CheckOutCartNotFoundErrorCode = 'CO109',
  CheckOutBranchNotFoundErrorCode = 'CO110',
  CheckOutModeNotSupportedByBranchErrorCode = 'CO111',
  CheckOutBranchIsBusyErrorCode = 'CO112',
  CheckOutAddressAreaNotMatchingCartAreaErrorCode = 'CO120',
  CheckOutAddressWithNoAreaErrorCode = 'CO121',
  CheckOutStoreCannotDeliverToYourAreaErrorCode = 'CO122',
  CheckOutOrderAmountIsZeroErrorCode = 'CO123',
  CheckOutOrderAmountIsLessThanRequiredErrorCode = 'CO124',
  CheckOutBranchIsOutOfHoursErrorCode = 'CO129',
  CheckOutBranchIsYetToOpenErrorCode = 'CO130',
  CheckOutBranchIsOffTodayErrorCode = 'CO131',
  CheckOutCashNotAvailableErrorCode = 'CO132',
  CheckOutCardOnDeliveryNotAvailableErrorCode = 'CO133',
  CheckOutPaymentMethodNotAvailableErrorCode = 'CO134',
  CheckOutCannotUsePaymentMethodErrorCode = 'CO135',
  CheckOutMissingPaymentTokenErrorCode = 'CO136',
  CheckOutOnlyScheduledOrdersAvailableErrorCode = 'CO137',
  CheckOutOnlyASAPOrdersAvailableErrorCode = 'CO138',
  CheckOutOrderScheduledAfterAllowedErrorCode = 'CO139',
  CheckOutOrderScheduledBeforeAllowedErrorCode = 'CO140',
  CheckOutVoucherCodeInvalidErrorCode = 'CO141',
  CheckOutVoucherCodeExceedsRedemptionErrorCode = 'CO142',
  CheckOutVoucherCodeNotValidForOrderTypeErrorCode = 'CO143',
  CheckOutVoucherCodeMinimumAmountNotMetErrorCode = 'CO144',
  CheckOutDailyCapacityInsufficientErrorCode = 'CO145',
  CheckOutDailyCapacityTransactionErrorCode = 'CO146',
  CheckOutRefetchTheCart = 'CO148',
  CheckOutInvalidTimeSlotErrorCode = 'CO126',
  CheckOutInvalidDeliveryZonesSystemAddressErrorCode = 'CO115',
  CheckOutNotEligibleVoucherItems = 'CO149',
  CheckOutInvalidHappyHourVoucher = 'CO150',
  // Cart Errors
  CartArchivedErrorCode = 'CRT101',
  CartLinkErrorCode = 'CRL100',
  CartExpiredErrorCode = 'CRL101',
  CartNotFound = 'CRT404',
}

export enum PageErrorCodes {
  CartArchivedInCheckoutPageErrorCode = 'CRT101.1',
}
