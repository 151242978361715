import { NODE_NEV_DEV } from 'common/constants';

function initializeConfig() {
  const config = {
    common: {
      cdnBaseUrl: process.env.NEXT_PUBLIC_CDN_BASE_URL!,
      assetsBaseUrl: process.env.NEXT_PUBLIC_ASSETS_BASE_URL!,
      nodeEnv: process.env.NEXT_PUBLIC_NODE_ENV ?? NODE_NEV_DEV, // Defaults to development environment
      storeUrl: process.env.NEXT_PUBLIC_STORE_URL,
      backendUrl: process.env.NEXT_PUBLIC_ORDERING_URL!,
      backendGraphQLUrl: process.env.NEXT_PUBLIC_BACKEND_URL_GRAPHQL!,
      dzmsGraphQLUrl: process.env.NEXT_PUBLIC_DZMS_URL_GRAPHQL!,
      verdGraphQLUrl: process.env.NEXT_PUBLIC_VERD_URL_GRAPHQL!,
      privateKey: process.env.NEXT_PUBLIC_PRIVATE_KEY!,
      publicAPIGW: process.env.NEXT_PUBLIC_APIGW_URL_GRAPHQL,
      sentryDsn: process.env.NEXT_PUBLIC_SENTRY_DSN!,
      googleMapKey: process.env.NEXT_PUBLIC_GOOGLE_MAP_KEY!,
      algoliaAppId: process.env.NEXT_PUBLIC_ALGOLIA_APP_ID!,
      algoliaIndexName: process.env.NEXT_PUBLIC_ALGOLIA_INDEX_NAME!,
      tapBlueBirdScriptUrl: process.env.NEXT_PUBLIC_TAP_BLUEBIRD_SCRIPT_URL!,
      tapScriptUrl: process.env.NEXT_PUBLIC_TAP_SCRIPT_URL!,
      encryptionSecret: process.env.NEXT_PUBLIC_ENCRYPTION_SECRET,
      orderFastUrl: process.env.NEXT_PUBLIC_ORDER_FAST_URL,
      geolocationServiceUrl: process.env.NEXT_PUBLIC_GEOLOCATION_SERVICE_URL,
      imagesDomains: process.env.NEXT_PUBLIC_IMAGES_DOMAINS,
      imagesCDNUrl: process.env.NEXT_PUBLIC_CDN_URL,
      zydaStaticUrl: process.env.NEXT_PUBLIC_ZYDA_STATIC_URL,
      fallbackImageUrl: process.env.NEXT_PUBLIC_FALLBACK_IMAGE,
      orderFastDomain: process.env.NEXT_PUBLIC_ORDER_FAST_DOMAIN,
      manualOrderDomain: process.env.NEXT_PUBLIC_MANUAL_ORDER_DOMAIN,
      zydaGTMId: process.env.NEXT_PUBLIC_ZYDA_GTM_ID,
      paciEndpoint: process.env.NEXT_PUBLIC_PACI_ENDPOINT,
    },
    serverSide: {},
  };

  return config;
}

const config = initializeConfig();

export default config;
