import { AddressBookWithDeliveryZone } from 'common/types';
import { useAddressBook, useCarBook } from 'service/hooks';
import { Car } from 'service/types';

type useDecideUserBookType = ({
  shouldQuery,
  isDelivery,
  isCarPickup,
  areaId,
}: {
  shouldQuery?: boolean;
  isDelivery: boolean;
  isCarPickup: boolean;
  areaId?: string;
}) => {
  data: AddressBookWithDeliveryZone | Array<Car>;
  isLoading: boolean;
  mutate: any;
};

const useDecideUserBook: useDecideUserBookType = ({ shouldQuery = true, isDelivery, isCarPickup, areaId }) => {
  const {
    data: addressBook,
    isLoading: isAddressesLoading,
    mutate: mutateAddressBook,
  } = useAddressBook({
    areaId,
    shouldQuery: shouldQuery && isDelivery,
  });
  const {
    data: cars,
    isLoading: isCarLoading,
    mutate: mutateCarBook,
  } = useCarBook({
    shouldQuery: shouldQuery && isCarPickup,
  });

  const isLoading = isAddressesLoading || isCarLoading;
  const mutate = isDelivery ? mutateAddressBook : mutateCarBook;

  return { data: addressBook || cars, isLoading, mutate };
};

export default useDecideUserBook;
