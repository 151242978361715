import styled, { createGlobalStyle } from 'styled-components';
import { Card } from '@zydalabs/storefront-components';

export const contentMaxWidth = '450px';

export const GlobalStyle = createGlobalStyle`
  /* set box-sizing to border-box */
  /* set default font */
  html, body {
    direction: ${props => props.theme.direction};
    box-sizing: border-box;
    touch-action: manipulation;
    -webkit-overflow-scrolling: touch;
    height: 100%;
  }
  #__next {
    height: 100%;
  }
  body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    height: 100%;
    background: white;
  }
  *,
  *:before,
  *:after {
    -webkit-tap-highlight-color: transparent;
    box-sizing: inherit;
    font-family: ${props => props.theme.fontFamily} /*rtl:prepend:"Droid Arabic Kufi",*/;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  // We use it to prevent safari from auto-zooming
  input:active {
    font-size: 16px;
  }
  
`;

export const StyledPageWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  background-color: ${props => props.theme.colors?.positiveAccent?.background};
  @media (min-width: calc(${props => props.theme.screenBreakpoints.large} + 0.2px)) {
    overflow-y: auto;
    overflow-x: hidden;
  }
`;

export const StyledAlignItemsBetween = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const StyledAlignItemsCenter = styled.div`
  display: flex;
  justify-content: Center;
  width: 100%;
`;

export const SectionWrapper = styled.div`
  margin: ${({ theme }) => `0 ${theme.space[4]}px ${theme.space[4]}px`};
`;

export const StyledStickySection = styled.div`
  position: sticky;
  bottom: 0;
  width: 100%;
  z-index: 998;
  margin-top: auto;
  display: flex;
  flex-direction: column;
  ${({ theme }) => `
    padding: ${theme.space[4]}px;
    gap: ${theme.spacing['14']};
    background-color: ${theme?.colors?.negative?.absolute};
  `}
`;

export const StyledFormWrapper = styled.div`
  ${({ theme }) => `padding: 0 ${theme.space[4]}px ${theme.space[8]}px ;
  background-color: ${theme.color.white};`}
`;

export const StyledListButtonWrapper = styled.div`
  ${({ theme }) => `
    padding:  ${theme.space[2]}px ${theme.space[1]}px;
    background:  ${theme.color.white};
    & button {
      min-width: unset;
    }
  `}
`;

export const StyledBlockWrapper = styled.div`
  padding: ${({ theme }) => `${theme.space[3]}px 0`};
  margin: ${({ theme }) => `0 ${theme.space[4]}px`};
  display: flex;
  justify-content: space-between;
  cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.color.border};

  & label {
    cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
  }
`;

export const FlexStart = styled.div`
  align-self: flex-start;
`;

export const StyledActionSheetWrapper = styled.div`
  @media (max-width: ${props => props.theme.screenBreakpoints.large}) {
    width: 100%;
  }
  @media (min-width: calc(${props => props.theme.screenBreakpoints.large} + 0.2px)) {
    width: ${contentMaxWidth};
  }
`;

export const StyledSlideCardsContainer = styled.div`
  ${({ theme }) => `
    display: flex;
    padding: 0 ${theme.space[4]}px;
    overflow-x: auto;
    overscroll-behavior-x: contain;
    background: ${theme?.colors?.negative?.absolute};
    scroll-snap-type: x mandatory;
    padding-top: ${theme.space[4]}px;
    scroll-padding: ${theme.space[4]}px;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    ::-webkit-scrollbar {
      display: none;
    }
    & ${Card} {
      flex: 0 0 100%;
      scroll-snap-align: start;
      :not(:last-child){
        margin-inline-end: ${theme.space[2]}px;
      }
    }
  `}
`;
export const StyledAppliedCard = styled.div`
  flex: 0 0 100%;
  scroll-snap-align: start;
  :not(:last-child) {
    margin-inline-end: ${props => props.theme.space[2]}px;
  }
  > div {
    width: 100%;
  }
`;

export const StyledCardContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  height: 100%;
`;

export const StyledCardFooter = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  margin-top: auto;
`;

export const StyledSectionShimmer = styled.div`
  background-color: ${props => props.theme.color.white};
  padding: ${props => props.theme.space[4]}px;
  margin-bottom: ${props => props.theme.space[4]}px;
`;

export const StyledAvailabilityTag = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.space[3]}px;
  left: ${({ theme }) => theme.space[3]}px;
`;

export const StyledStickyButton = styled(StyledStickySection)`
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ theme }) => `${theme.space[4]}px ${theme.space[4]}px`};
  border-top: 1px solid ${({ theme }) => theme.color.border};
`;

export const StyledAlignCashbackItemsCenter = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledBoxWrapper = styled.div`
  padding-bottom: 150px;
`;

export const StyledIconButtonWrapper = styled.span<{ hasMargin: boolean }>`
  display: flex;
  height: 44px;
  width: 44px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  ${({ hasMargin, theme }) => hasMargin && `margin-inline-end: ${theme.space[2]}px`};
  background-color: ${({ theme }) => theme.color.grey4};
`;
