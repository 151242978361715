import ServiceConfigs from '../../service/config';
import { usePersistedCartData, usePersistedCartDataBackup, usePersistedUserAddressId } from '.';

const useHandleCartDataBackup = () => {
  const loggedIn = !!ServiceConfigs.getUserToken();
  const [cart, setCartData, removeCartdata] = usePersistedCartData();
  const [cartDataBackup, setCartDataBackup, removeCartDataBackup] = usePersistedCartDataBackup();
  const [, , removeAddressId] = usePersistedUserAddressId();
  const backupUnauthorizedCartData = () => {
    if (cart && cart.isCartAuthorized) {
      setCartDataBackup(cart);
      removeCartdata();
      removeAddressId();
    }
  };

  const restoreAuthorizedCartBackup = () => {
    if (!cart && cartDataBackup) {
      setCartData(cartDataBackup);
    }
    removeCartDataBackup();
  };

  const handleCartDataBackup = () => {
    if (loggedIn) {
      restoreAuthorizedCartBackup();
    } else {
      backupUnauthorizedCartData();
    }
  };

  return handleCartDataBackup;
};

export default useHandleCartDataBackup;
