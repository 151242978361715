export interface RenderingRegistryType {
  isManualOrder?: boolean;
  layout?: Record<string, number>;
  showVouchers?: boolean;
  showCoverImage?: boolean;
  showReordering?: boolean;
  operatorId?: string;
  hasReferenceId?: boolean;
  isWalletUsed?: boolean;
}

export interface IStoreDefaultThemeLayout {
  categoryLayout: number;
  productLayout: number;
  fontShape: string;
  buttonRounded: boolean;
  logoRounded: boolean;
}

export interface IRenderingRegistry {
  isManualOrder: boolean;
  layout: Record<string, number>;
  showVouchers: boolean;
  showCoverImage: boolean;
  showReordering: boolean;
  operatorId: string;
  hasReferenceId: boolean;
  isWalletUsed: boolean;
  hasActiveVouchers?: boolean;
  setIsManualOrdering: () => void;
  setIsWalletUsed: (flag: boolean) => void;
  setOperatorId: (id: string) => void;
  init: (layout: IStoreDefaultThemeLayout) => void;
  getIsManualOrder: () => boolean;
  getShowVouchers: () => boolean;
  getShowCoverImage: () => boolean;
  getShowReordering: () => boolean;
  getShowLayout: () => IStoreDefaultThemeLayout;
  getOperatorId: () => string;
  setHasReferenceId: () => void;
  getHasReferenceId: () => boolean;
  clearRegistry: () => void;
  setHasActiveVouchers: (hasVouchers: boolean) => void;
}

const RenderingRegistry: IRenderingRegistry = {
  isManualOrder: false,
  layout: undefined,
  showVouchers: true,
  showCoverImage: true,
  showReordering: true,
  operatorId: undefined,
  hasReferenceId: false,
  isWalletUsed: false,
  hasActiveVouchers: false,
  setIsManualOrdering(): void {
    this.isManualOrder = true;
  },
  setHasReferenceId(): void {
    this.hasReferenceId = !this.isManualOrder && true;
  },
  setHasActiveVouchers(hasActiveVoucher: boolean): void {
    this.hasActiveVouchers = hasActiveVoucher;
  },
  init(layout: IStoreDefaultThemeLayout): void {
    this.layout = this.isManualOrder ? { ...layout, categoryLayout: 2, productLayout: 1 } : layout;
    this.showVouchers = !this.isManualOrder;
    this.showCoverImage = !this.isManualOrder;
  },
  getShowVouchers(): boolean {
    return this.showVouchers && this.hasActiveVouchers;
  },
  getShowCoverImage(): boolean {
    return this.showCoverImage;
  },
  getShowReordering(): boolean {
    return this.showReordering;
  },
  getIsManualOrder(): boolean {
    return this.isManualOrder;
  },
  getShowLayout(): IStoreDefaultThemeLayout {
    return this.layout;
  },
  setOperatorId(id: string): void {
    this.operatorId = id;
  },
  getOperatorId(): string {
    return this.operatorId;
  },
  getHasReferenceId(): boolean {
    return this.hasReferenceId;
  },
  setIsWalletUsed(flag: boolean): void {
    this.isWalletUsed = flag;
  },
  clearRegistry(): void {
    this.isManualOrder = false;
    this.layout = undefined;
    this.showVouchers = true;
    this.showCoverImage = true;
    this.showReordering = true;
    this.operatorId = undefined;
    this.hasReferenceId = false;
    this.isWalletUsed = false;
  },
};

export default RenderingRegistry;
