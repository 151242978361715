import { useLocalStorage, LocalStorageHookResult } from '@zyda/swr-internal-state';

import { prefixLocalStorageKey } from 'common/utils';
import { CoordsType } from 'common/types';
import { Area, Scalars, Voucher, RecipientInfo, DeliveryZoneMs } from '../../../service/types/generated';
import { CartDataType } from '../../../modules/productsModule/hooks/cart/types';
import {
  PersistedFulfillmentTimeType,
  persistedCountryDetailsType,
  PersistedUserUmbrellaType,
  PersistedCurrentEventType,
} from './types';

export const usePersistedAreaId = (): LocalStorageHookResult<Area> =>
  useLocalStorage<Area>(prefixLocalStorageKey('selected-area'));

export const usePersistedOrderMode = (): LocalStorageHookResult<string> =>
  useLocalStorage<string>(prefixLocalStorageKey('selected-mode'));

export const usePersistedCountryDetails = (): LocalStorageHookResult<persistedCountryDetailsType> =>
  useLocalStorage<persistedCountryDetailsType>(prefixLocalStorageKey('country-details'));

export const usePersistedBranchId = (): LocalStorageHookResult<Scalars['ID']> =>
  useLocalStorage<Scalars['ID']>(prefixLocalStorageKey('branch-id'));

export const usePersistedWalletUsed = (): LocalStorageHookResult<boolean> =>
  useLocalStorage<boolean>(prefixLocalStorageKey('is-wallet-used'));

export const usePersistedCartData = (): LocalStorageHookResult<CartDataType> =>
  useLocalStorage<CartDataType>(prefixLocalStorageKey('cart-data'));

export const usePersistedFulfillmentTimeAndType = (): LocalStorageHookResult<PersistedFulfillmentTimeType> =>
  useLocalStorage<PersistedFulfillmentTimeType>(prefixLocalStorageKey('fulfillment-time-type'));

export const usePersistedPaymentCard = (): LocalStorageHookResult<any> =>
  useLocalStorage<any>(prefixLocalStorageKey('payment-card-info'));

export const usePersistedPaymentRedirected = (): LocalStorageHookResult<Record<string, string | boolean>> =>
  useLocalStorage<Record<string, string | boolean>>(prefixLocalStorageKey('payment-redirected'));

export const usePersistedUserAddressId = (): LocalStorageHookResult<string> =>
  useLocalStorage<string>(prefixLocalStorageKey('selected-user-address-id'));

export const usePersistedUserCarId = (): LocalStorageHookResult<string> =>
  useLocalStorage<string>(prefixLocalStorageKey('selected-user-car-id'));

export const usePersistedUserUmbrellaData = (): LocalStorageHookResult<PersistedUserUmbrellaType> =>
  useLocalStorage<PersistedUserUmbrellaType>(prefixLocalStorageKey('umbrella-data'));

export const usePersistedSelectedCoordinates = (): LocalStorageHookResult<CoordsType> =>
  useLocalStorage<{ lat: number; lng: number }>(prefixLocalStorageKey('selected-coordinates'));

export const usePersistedAppliedVoucherCode = (): LocalStorageHookResult<Voucher['code']> =>
  useLocalStorage<Voucher['code']>(prefixLocalStorageKey('applied-voucher-code'));

export const usePersistedRecipientInfo = (): LocalStorageHookResult<RecipientInfo> =>
  useLocalStorage<RecipientInfo>(prefixLocalStorageKey('recipient-info'));

export const usePersistedAddressArea = (): LocalStorageHookResult<Area> =>
  useLocalStorage<Area>(prefixLocalStorageKey('address-area'));

export const usePersistedSupportedDeliveryZone = (): LocalStorageHookResult<DeliveryZoneMs> =>
  useLocalStorage<DeliveryZoneMs>(prefixLocalStorageKey('supported-delivery-zone'));

export const usePersistedAreaTitle = (): LocalStorageHookResult<string> =>
  useLocalStorage<string>(prefixLocalStorageKey('area-title'));

export const usePersistedCurrentEvent = (): LocalStorageHookResult<PersistedCurrentEventType> =>
  useLocalStorage<PersistedCurrentEventType>(prefixLocalStorageKey('current-event'));

export const usePersistedCartDataBackup = (): LocalStorageHookResult<CartDataType> =>
  useLocalStorage<CartDataType>(prefixLocalStorageKey('cart-data-backup'));

export const usePersistedShowDyma = (): LocalStorageHookResult<boolean> =>
  useLocalStorage<boolean>(prefixLocalStorageKey('show-dyma'), true);

export const usePersistedSelectedOptions = (): LocalStorageHookResult<any> =>
  useLocalStorage<any>(prefixLocalStorageKey('selected-options'));

export const usePersistedVoucherError = (): LocalStorageHookResult<string> =>
  useLocalStorage<string>(prefixLocalStorageKey('voucher-error'));

export const usePersistedOperatorId = (): LocalStorageHookResult<string> =>
  useLocalStorage<string>(prefixLocalStorageKey('operator-id'));

export const usePersistedReferenceId = (): LocalStorageHookResult<string> =>
  useLocalStorage<string>(prefixLocalStorageKey('reference-id'));
