import { paletteTypes } from './themeTypes';

/**
 *  assign the store colors values to app theme
 *
 * @param  {Object} palette
 * @param  {String} mode=DYNAMIC
 *
 * @returns {Object} of all available colors in our theme
 */
const getStoreColors = (palette: paletteTypes): string => {
  let primaryColor;

  if (palette) {
    const palettePrimaryColor = palette.primary;
    if (palettePrimaryColor) {
      primaryColor = palettePrimaryColor;
    }
  }
  return primaryColor;
};

export default getStoreColors;
