import { DATA_LAYER_EVENTS, DATA_LAYER_CONTENT_TYPE, DATA_LAYER_FLUSH_OBJECTS } from 'common/constants';
import { DataLayerConfigs } from 'common/lib/tracking';
import {
  preparePurchaseEventArgumentsType,
  preparePurchaseFacebookPixelEventArgumentsType,
  pushPurchaseEventToDataLayerType,
  pushAddToCartEventToDataLayerType,
  pushPageViewEventToDataLayerType,
  pushLoginEventToDataLayerType,
  flushType,
  pushViewItemEventToDataLayerType,
} from './types';

/**
 *
 * @param {Object[]} cartItems Items present in the cart
 * @param {Object} cartItems[].menuItem
 * @param {string} cartItems[].menuItem.uuid
 * @param {string} cartItems[].menuItem.titleEn
 * @param {Object} cartItems[].variant
 * @param {string} cartItems[].variant.titleEn
 * @param {number} cartItems[].variantQuantity
 * @param {number} cartItems[].unitPrice
 * @param {string} currency Type of value currency
 * @param {number} value Total cart value
 * @returns full event object to be consumed by the dataLayer
 */
export const preparePurchaseEventArguments: preparePurchaseEventArgumentsType = (
  cartItems,
  currency,
  value,
  utmSource,
  utmMedium,
  utmCampaign,
) => ({
  contents: cartItems.map(({ menuItem, variant, variantQuantity, totalPrice }) => ({
    item_id: menuItem.uuid,
    quantity: variantQuantity,
    item_variant: variant.titleEn,
    item_name: menuItem.titleEn,
    price: totalPrice,
  })),
  content_type: DATA_LAYER_CONTENT_TYPE.PRODUCT,
  currency,
  value,
  utm_source: utmSource,
  utm_medium: utmMedium,
  utm_campaign: utmCampaign,
  num_items: cartItems.reduce((totalNum, item) => totalNum + item.variantQuantity, 0),
});

/**
 *
 * @param {Object[]} cartItems  Items present in the cart
 * @param {Object} cartItems[].menuItem Menu Item Object
 * @param {number} cartItems[].variantQuantity the amount of the selected variant
 * @param {string} cartItems[].menuItem.uuid unique id of the item
 * @param {string} currency Type of value currency
 * @param {number} value Total cart value
 * @returns full event object to be consumed by the facebook pixel
 */
export const prepareFacebookPixelPurchaseEventArguments: preparePurchaseFacebookPixelEventArgumentsType = (
  cartItems,
  currency,
  value,
  capiEnabled,
  utmSource,
  utmMedium,
  utmCampaign,
) => ({
  ...(capiEnabled
    ? {}
    : {
        contents: cartItems.map(({ menuItem, variantQuantity, totalPrice }) => ({
          id: menuItem.uuid,
          quantity: variantQuantity,
          title: menuItem.titleEn,
          item_price: totalPrice || 0,
        })),
      }),
  content_type: DATA_LAYER_CONTENT_TYPE.PRODUCT,
  currency,
  utm_source: utmSource,
  utm_medium: utmMedium,
  utm_campaign: utmCampaign,
  value: value || 0,
  num_items: cartItems.reduce((totalNum, item) => totalNum + item.variantQuantity, 0),
});

/**
 * Flushes the dataLayer by sending the same attributes as the last event as undefined to circumvent
 * the data inheritance issue that happens in SPA, where the attributes from a previous event will
 * appear in the next event
 * @param {string} event The event that was pushed and requires data flushing
 */
export const flush: flushType = event => {
  DataLayerConfigs.pushToDataLayer({
    event: DATA_LAYER_EVENTS.FLUSH,
    ...DATA_LAYER_FLUSH_OBJECTS[event],
  });
};

/**
 * Pushes purchase event through to the data layer then flushes it to avoid data inheritance
 * @param purchaseEventArguments the purchase event object shape as required in GTM (purchaseEventArgumentsType)
 * @param orderNumber Order number of the purchase event
 */
export const pushPurchaseEventToDataLayer: pushPurchaseEventToDataLayerType = (purchaseEventArguments, orderNumber) => {
  DataLayerConfigs.pushToDataLayer({
    event: DATA_LAYER_EVENTS.PURCHASE,
    ...purchaseEventArguments,
    orderNumber,
  });
  flush(DATA_LAYER_EVENTS.PURCHASE);
};

/**
 * Pushes add_to_cart event through to the data layer then flushes it to avoid data inheritance
 * @param addToCartObject the addToCart event object shape as required in GTM (AddToCartObjectType)
 */
export const pushAddToCartEventToDataLayer: pushAddToCartEventToDataLayerType = addToCartObject => {
  DataLayerConfigs.pushToDataLayer({
    event: DATA_LAYER_EVENTS.ADD_TO_CART,
    ...addToCartObject,
  });
  flush(DATA_LAYER_EVENTS.ADD_TO_CART);
};

/**
 * Pushes add_to_cart event through to the data layer then flushes it to avoid data inheritance
 * @param path current router.asPath
 */
export const pushPageViewEventToDataLayer: pushPageViewEventToDataLayerType = path => {
  DataLayerConfigs.pushToDataLayer({
    event: DATA_LAYER_EVENTS.PAGE_VIEW,
    path,
  });
  flush(DATA_LAYER_EVENTS.PAGE_VIEW);
};

/**
 * Pushes login event through to the data layer
 * @param storefrontUser an object containing the storefront user
 */
export const pushLoginEventToDataLayer: pushLoginEventToDataLayerType = storefrontUser => {
  DataLayerConfigs.pushToDataLayer({
    event: DATA_LAYER_EVENTS.LOGIN,
    ...storefrontUser,
  });
};

/**
 * Pushes view_item event through to the data layer then flushes it to avoid data inheritance
 * @param viewItemObject an object containing the items details
 */
export const pushViewItemEventToDataLayer: pushViewItemEventToDataLayerType = viewItemObject => {
  DataLayerConfigs.pushToDataLayer({
    event: DATA_LAYER_EVENTS.VIEW_ITEM,
    ...viewItemObject,
  });
  flush(DATA_LAYER_EVENTS.VIEW_ITEM);
};
