import { CLIENT_TYPE } from 'common/constants';
import { v4 as uuidv4 } from 'uuid';
import jwt from 'jsonwebtoken';
import ServiceConfigs from 'service/config';
import { Handler } from '../types/internal';
import clients from './client';

function delay(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

async function requestWithRetry(requestPromise, { retries = 3, everyMs = 1000 }, retriesCount = 0) {
  try {
    return await requestPromise;
  } catch (e) {
    const updatedCount = retriesCount + 1;
    if (updatedCount > retries) {
      throw e;
    }
    await delay(everyMs);
    // eslint-disable-next-line no-return-await
    return await requestWithRetry(requestPromise, { retries, everyMs }, updatedCount);
  }
}

type FetcherArgs<T> = {
  query: string;
  clientType?: string;
  variables?: string;
  clientName?: string;
  requestHeaders?: Record<string, string>;
  handler?: Handler<T>;
  retryCount?: number;
};

export async function fetcher<T>({
  query,
  clientType = CLIENT_TYPE.CLIENT,
  variables = JSON.stringify({}),
  requestHeaders = {},
  handler = response => response as T,
}: FetcherArgs<T>): Promise<T> {
  const timestamp = Date.now();
  const requestId = uuidv4();
  const tokenPayload = { timestamp, requestId };
  const jwtSecret = jwt.sign(tokenPayload, ServiceConfigs.getPrivateKey());
  const client = clients[clientType];
  const response = await requestWithRetry(
    client.request(query, JSON.parse(variables), { ...requestHeaders, bartoso: jwtSecret }),
    { retries: 5 },
  );

  return handler(response);
}
