import { mutate } from 'swr';

import { prefixLocalStorageKey } from '../utils';

const useRemoveLocaleStorage = () => {
  const removeLocaleStorage = async () => {
    const matcher = prefixLocalStorageKey('');
    const keys = [];

    Object.keys(localStorage).forEach(key => {
      if (key.startsWith(matcher)) {
        localStorage.removeItem(key);
        keys.push(key);
      }
    });

    const mutations = keys.map(key => mutate(key, null));
    return Promise.all(mutations);
  };

  return removeLocaleStorage;
};

export default useRemoveLocaleStorage;
