import { storeLayoutQuery } from '../../queries';
import { QueryHookResponse } from '../../types/internal';
import ServiceConfigs from '../../config';
import { Store } from '../../types/generated';
import { useQuery } from '../../utils';

type UseLayoutType = ({ initialData }?: { initialData?: Store }) => QueryHookResponse<Store>;

// TODO: make test case to make sure that order mode in query is arranged at the right order(delivery/pickup/carpickup)

const useLayout: UseLayoutType = ({ initialData } = {}) =>
  useQuery<Store>({
    query: storeLayoutQuery,
    variables: ServiceConfigs.getSubDomain() ? { subdomain: ServiceConfigs.getSubDomain() } : null,
    handler: (res: any) => res.store,
    config: { initialData },
  });

export default useLayout;
