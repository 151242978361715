import { isNil } from 'ramda';

import config from 'common/config';
import { NODE_ENV_LOCAL, NODE_ENV_PROD, NODE_ENV_STG, NODE_NEV_DEV } from 'common/constants';
import { PROTOCOL_REGEX } from 'common/constants/regex';
import { ContextRegistry } from 'common/lib/registries';
import { isServerSide } from 'common/utils/misc';
import ServiceConfigs from '../../../service/config';
import logger from '../logger';

type NormalizeUrl = (url: string, options?: { removeProtocol: boolean }) => string;

/**
 * Removes any trailing spaces or slashes from given URL.
 *
 * @param url
 * @param options
 * @param options.removeProtocol
 */
export const normalizeUrl: NormalizeUrl = (url, { removeProtocol } = { removeProtocol: false }) => {
  // Remove any trailing spaces
  const normalizedUrl = url.replace(/[/\s]+$/, '');

  // Remove protocol if necessary
  if (removeProtocol) return normalizedUrl.replace(PROTOCOL_REGEX, '');

  return normalizedUrl;
};

/**
 * Gets the requested URL.
 * This only works when making a request i.e. in `getInitialProps` and will not always work in pages.
 */
export const getRequestedUrl: () => string = () => {
  if (isServerSide()) {
    const { req, asPath } = ContextRegistry.getPageContext()!;
    return `${req!.headers.host}${asPath}`;
  }
  return `${normalizeUrl(window.location.origin, { removeProtocol: true })}`;
};

/**
 * Gets the current host name.
 */
export const getCurrentHostName: () => string = () => {
  const currentUrl = getRequestedUrl();

  const originRegex = /^[^/]+/; // This will match everything until the first slash
  const match = currentUrl.match(originRegex);

  if (match === null || match === undefined) {
    throw new Error(`Cannot find host name in ${currentUrl}`);
  }
  const [origin] = match;

  return origin;
};

/**
 * Gets the current URL.
 * - In server side: this will get the requested URL.
 * - In client side: this will get the current page url.
 */
export const getCurrentUrl: () => string = () => {
  if (isServerSide()) {
    return getRequestedUrl();
  }
  return normalizeUrl(window.location.href, { removeProtocol: true });
};

/**
 * Gets the base URL.
 * - In server side: this will get the requested URL.
 * - In client side: this will get the current page base url.
 */
export const getCurrentBaseUrl: () => string = () => {
  if (isServerSide()) {
    const { req } = ContextRegistry.getPageContext()!;
    return `${req!.headers.host}`;
  }
  return normalizeUrl(window.location.origin, { removeProtocol: false });
};

export const getFullStoreURL = (): string => {
  const protocol = config.common.nodeEnv === NODE_ENV_LOCAL ? 'http' : 'https';
  const storeUrl = isServerSide() ? `${protocol}://${getCurrentBaseUrl()}` : getCurrentBaseUrl();
  const orderfastStore = ServiceConfigs.getOrderFastStore();
  return orderfastStore ? `${storeUrl}/${orderfastStore}` : storeUrl;
};

/**
 * Gets store url.
 */
export const getStoreUrl: () => string = () => {
  switch (config.common.nodeEnv) {
    case NODE_ENV_LOCAL:
    case NODE_NEV_DEV:
    case NODE_ENV_STG: {
      return getNonProdStoreUrl();
    }

    case NODE_ENV_PROD: {
      return getProdStoreUrl();
    }

    default:
      throw new Error(`Unhandled node environment [${config.common.nodeEnv}]`);
  }
};

/**
 * Helper function to get the store url for non production stores.
 */
const getNonProdStoreUrl = (): string => {
  const url = config.common.storeUrl && !isOrderFastUrl() ? config.common.storeUrl : getCurrentHostName();
  return normalizeUrl(url, {
    removeProtocol: true,
  });
};

/**
 * Helper function to get the store url for production stores.
 */
const getProdStoreUrl = (): string => {
  if (isServerSide()) return String(ContextRegistry.getPageContext()!.req!.headers['x-forwarded-host']!);
  return getCurrentHostName();
};

/**
 * Gets store slug
 */
export const getStoreSlug = (): string => {
  const storeUrl = getStoreUrl();
  const storeUrlSections = storeUrl.split('.');
  const slug = storeUrlSections[1];
  if (slug === config.common.orderFastDomain) return null;
  if (storeUrlSections[0] === config.common.manualOrderDomain) return null;
  if (slug === 'zyda' || slug === 'com' || slug?.includes('localhost:')) return storeUrlSections[0];
  return slug;
};

/**
 * Gets if the store is accessed by the manual order domain
 */
export const getUrlTypeFlags = (): Record<string, boolean> => {
  const storeUrl = getStoreUrl();
  const storeUrlSections = storeUrl.split('.');
  const slug = storeUrlSections[0];
  const domain = storeUrlSections[1];
  const urlTypeFlags = { isOrderFast: false, isManualOrder: false };
  if (config.common.manualOrderDomain && config.common.manualOrderDomain === slug) {
    urlTypeFlags.isManualOrder = true;
  }
  if (domain === config.common.orderFastDomain) {
    urlTypeFlags.isOrderFast = true;
  }

  return urlTypeFlags;
};

/**
 * Gets assets base url.
 * - returns the cdn url if exists.
 * - return the host url if the cdn url is not existing.
 */

export const getAssetsBaseUrl = (): string => {
  const baseUrl = config.common.cdnBaseUrl;
  if (isNil(baseUrl)) {
    let hostName = getCurrentHostName();
    const storeUrlSections = hostName.split('.');
    const slug = storeUrlSections[1];
    if (slug?.includes('localhost:')) hostName = slug;
    const protocol = config.common.nodeEnv === NODE_ENV_LOCAL ? 'http' : 'https';
    return `${protocol}://${hostName}`; // eslint-disable-line no-param-reassign
  }
  return baseUrl;
};

/**
 * Gets image url.
 *
 * @param image <string>
 *
 * @returns imageUrl <string>
 */

export const constructImageUrl = (image: string): string => {
  const baseUrl = getAssetsBaseUrl();
  return `${baseUrl}/assets/images/${image}`;
};

/**
 * Checks if current url is an orderfast url
 *
 */
const isOrderFastUrl = (): boolean => getCurrentHostName().includes(config.common.orderFastDomain);

/**
 * Fetches the current time from the World Time API.
 *
 * @async
 * @function fetchServerTime
 * @returns {Promise<number>} The current time in milliseconds since the Unix Epoch.
 * @throws Will throw an error if the fetch operation fails.
 */
export const fetchServerTime = async (): Promise<number> => {
  try {
    const response = await fetch('https://worldtimeapi.org/api/timezone/Etc/UTC');
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data.unixtime * 1000; // Convert seconds to milliseconds
  } catch (error) {
    logger.error({ error: 'fetchServerTime function to fetch time server-side error', extraData: error });
    throw error;
  }
};

/**
 * Gets the full domain for cookie setting, including all subdomains.
 */
export const getFullDomain = (): string => {
  const storeUrl = getStoreUrl();
  // Extract just the domain part, removing protocol, path, and query parameters
  const domainOnly = storeUrl
    .replace(/^https?:\/\//, '')
    .split('/')[0]
    .split('?')[0];
  let parts = domainOnly.split('.');
  // Remove 'www' if it's the first part, otherwise keep all parts
  if (parts[0].toLowerCase() === 'www') {
    parts = parts.slice(1);
  }
  // Join all parts and add leading dot to include all subdomains
  return `.${parts.join('.')}`;
};
