import { Translate } from 'next-translate';

export interface ErrorsConfigType {
  t?: Translate;
  onModalError: (error: Record<any, any>) => void;
  onSnackBarError: (error: Record<any, any>) => void;
  setErrorHandlers: ({
    onModalError,
    onSnackBarError,
  }: {
    onModalError: (error: Record<any, any>) => void;
    onSnackBarError: (error: Record<any, any>) => void;
  }) => void;
  init: ({ t }: { t: Translate }) => void;
}

const ErrorsConfig: ErrorsConfigType = {
  t: undefined,
  onModalError: null,
  onSnackBarError: null,

  init({ t }) {
    this.t = t;
  },

  setErrorHandlers({ onModalError, onSnackBarError }): void {
    this.onModalError = onModalError;
    this.onSnackBarError = onSnackBarError;
  },
};

export default ErrorsConfig;
