import { gql } from 'graphql-request';

const carBookQuery = gql`
  query {
    cars {
      id
      color
      make
      licenseNumber
    }
  }
`;

export default carBookQuery;
