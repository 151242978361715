import { useGlobalState, GlobalStateHookResult } from '@zyda/swr-internal-state';
import { AddressTypes, GlobalProductDetailsType, SnackBarProps } from 'common/types';
import { EstimatedTimeType } from '../../../modules/locationsModule/screens/EstimatedTimePage/types';

export const useGlobalOrderTypeAndTime = (): GlobalStateHookResult<EstimatedTimeType> =>
  useGlobalState<EstimatedTimeType>('order-type-and-time');

export const useGlobalTapPaymentDidLoad = (): GlobalStateHookResult<boolean> => useGlobalState<boolean>('tap-payment-did-load');

export const useGlobalSnackBar = (): GlobalStateHookResult<SnackBarProps> => useGlobalState<SnackBarProps>('snackBar');

export const useGlobalAddressType = (addressType: AddressTypes): GlobalStateHookResult<AddressTypes> =>
  useGlobalState<AddressTypes>('address-type', addressType);

export const useGlobalLoadingCart = (): GlobalStateHookResult<boolean> => useGlobalState<boolean>('loading-cart');

export const useGlobalInitializingCart = (): GlobalStateHookResult<boolean> => useGlobalState<boolean>('initializing-cart');

export const useGlobalDefaultOrderMode = (): GlobalStateHookResult<string> =>
  useGlobalState<string>('global-filtered-order-mode');

export const useGlobalProductDetails = (): GlobalStateHookResult<GlobalProductDetailsType> =>
  useGlobalState<GlobalProductDetailsType>('item-details');
