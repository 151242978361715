import { useEffect } from 'react';
import { isNil } from 'ramda';
import useSWR, { SWRConfiguration, cache } from 'swr';

import { CLIENT_TYPE } from 'common/constants';
import { Handler, QueryHookResponse } from '../types/internal';
import { stringify } from './misc';
import { fetcher } from './fetch';
import { ErrorHandler } from '../../common/lib/errorHandler';

type UseQueryParams<Data, Error> = {
  query: string;
  variables: unknown;
  clientType?: string;
  requestHeaders?: Record<string, string>;
  handler?: Handler<Data>;
  initialData?: Data;
  clientName?: string;
  config?: SWRConfiguration<Data, Error>;
};

export function useQuery<Data, Error = any>({
  query,
  variables,
  // TODO check if we need this default value or not
  requestHeaders = {},
  clientType = CLIENT_TYPE.CLIENT,
  handler,
  config,
  clientName,
}: UseQueryParams<Data, Error>): QueryHookResponse<Data, Error> {
  // it Will make the call in case the variables is not null only
  let key;
  if (variables) {
    if (!isNil(requestHeaders)) {
      key = [query, stringify(variables), stringify(requestHeaders)];
    } else {
      key = [query, stringify(variables)];
    }
  } else {
    key = null;
  }

  const { revalidateOnMount, ...restConfig } = config || {};

  const { data, error, ...rest } = useSWR<Data, Error>(
    key,
    (q: string, v: string) =>
      fetcher({
        query: q,
        variables: v,
        clientType,
        requestHeaders,
        clientName,
        handler,
      }),
    {
      revalidateOnFocus: false,
      shouldRetryOnError: true,
      revalidateOnMount: revalidateOnMount || !cache.has(key),
      onErrorRetry: (err: any, swrErrorKey, swrErrorConfig, revalidate, { retryCount }) => {
        // Only retry up to 3 times.
        if (retryCount > 3) return;
        // Only retry on 500 or more.
        if (err?.response?.status >= 500) {
          revalidate({ retryCount });
        }
      },
      ...restConfig,
    },
  );
  useEffect(() => {
    if (error) {
      const err = JSON.stringify({ ...error, requestSchema: query, variables }, undefined, 2);
      ErrorHandler(JSON.parse(err));
    }
  }, [error]);

  return { data, error, isLoading: variables && isNil(data), ...rest };
}
