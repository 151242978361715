import { gql } from 'graphql-request';

const cartLinkDataQuery = gql`
  query ($referenceId: String!) {
    cartLinkData(referenceId: $referenceId) {
      data
    }
  }
`;
export default cartLinkDataQuery;
