import { gql } from 'graphql-request';

const addressFragment = gql`
  fragment address on Address {
    id
    userId
    title
    street
    block
    building
    unitType
    unitNo
    covered
    lat
    lng
    areaId
    areaName
    cityName
    phoneNumber
    dzmsVerified
    notes
    dzmsVerified
    area {
      id
      titleAr
      titleEn
    }
  }
`;

const addressBookQuery = gql`
  query addressBook($subdomain: String!, $areaId: Int) {
    addressBook(subdomain: $subdomain, areaId: $areaId) {
      addresses {
        ...address
      }
      recentlyUsedAddressPerArea {
        ...address
      }
    }
  }
  ${addressFragment}
`;
export default addressBookQuery;
