import { CustomActionsEnum, CustomVariableEnum } from 'common/enums';

export interface ICustomActionsRegistry {
  customActions: Map<CustomActionsEnum, () => void>;
  customVariable: Map<CustomVariableEnum, string>;
  setCustomAction?: ({ key, fn }: { key: CustomActionsEnum; fn: () => void }) => void;
  setCustomVariable?: ({ key, value }: { key: CustomVariableEnum; value: string }) => void;
  getCustomVariable?: (key: string) => string;
}

const CustomActionsRegistry: ICustomActionsRegistry = {
  customActions: new Map(),
  customVariable: new Map(),

  setCustomAction({ key, fn }): void {
    this.customActions.set(key, fn);
  },

  setCustomVariable({ key, value }): void {
    this.customVariable.set(key, value);
  },

  getCustomVariable(key): string {
    return this.customVariable.get(key);
  },
};

export default CustomActionsRegistry;
