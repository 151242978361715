export const DATA_LAYER_EVENTS = {
  ADD_TO_CART: 'add_to_cart',
  PURCHASE: 'purchase',
  PAGE_VIEW: 'page_view',
  LOGIN: 'login',
  VIEW_ITEM: 'view_item',
  FLUSH: 'flush',
};

export const DATA_LAYER_VARIABLES = {
  UNIVERSAL_ANALYTICS_TRACK_ID: 'universalAnalyticsTrackId',
  GOOGLE_ANALYTICS_4_TRACK_ID: 'googleAnalytics4TrackId',
  STORE_FRONT_USER: 'storeFrontUser',
};

export const DATA_LAYER_CONTENT_TYPE = {
  PRODUCT: 'product',
};

export const DATA_LAYER_FLUSH_OBJECTS = {
  [DATA_LAYER_EVENTS.ADD_TO_CART]: {
    contents: undefined,
    content_type: undefined,
    currency: undefined,
    value: undefined,
  },
  [DATA_LAYER_EVENTS.PURCHASE]: {
    contents: undefined,
    content_type: undefined,
    currency: undefined,
    value: undefined,
    num_items: undefined,
    orderNumber: undefined,
  },
  [DATA_LAYER_EVENTS.VIEW_ITEM]: {
    currency: undefined,
    value: undefined,
    contents: undefined,
  },
  [DATA_LAYER_EVENTS.PAGE_VIEW]: { path: undefined },
  [DATA_LAYER_VARIABLES.STORE_FRONT_USER]: { [DATA_LAYER_VARIABLES.STORE_FRONT_USER]: undefined },
};
