import { AddressWithDeliveryZone } from 'common/types';

export const bulkCheckSupportedZone: (
  addressesArray: Array<Partial<AddressWithDeliveryZone>>,
  bulkCheckSupportedZoneCallBack: any,
  storeId: string,
  shouldHideUnverified?: boolean,
) => any = async (addressesArray, bulkCheckSupportedZoneCallBack, storeId, shouldHideUnverified = false) => {
  let addresses;
  try {
    const response = await bulkCheckSupportedZoneCallBack({
      addresses: addressesArray.map(address => ({
        restaurantReferenceId: parseFloat(storeId),
        geoPoint: [parseFloat(address.lng) || 0, parseFloat(address.lat) || 0],
        addressId: parseFloat(address.id),
      })),
    });

    addresses = addressesArray
      .map(address => {
        const { deliveryZone } = response.find(responseItem => responseItem.addressId === address.id);
        return {
          ...address,
          deliveryZone,
          covered: !!deliveryZone,
        };
      })
      .sort((zoneX, zoneY) => {
        if (zoneX.covered === zoneY.covered) return 0;
        if (zoneX.covered) return -1;
        return 1;
      });

    return shouldHideUnverified ? addresses.filter((address: AddressWithDeliveryZone) => address.dzmsVerified) : addresses;
  } catch (err: any) {
    addresses = [];
    return addresses;
  }
};
