import { Car } from '../../types/generated';
import { useQuery } from '../../utils';
import { QueryHookResponse } from '../../types/internal';
import ServiceConfigs from '../../config';
import carBookQuery from '../../queries/car/carBook';

type useCarBookType = ({
  initialData,
  shouldQuery,
}?: {
  initialData?: Array<Car>;
  shouldQuery?: boolean;
}) => QueryHookResponse<Array<Car>>;

const useCarBook: useCarBookType = ({ initialData, shouldQuery = true } = {}) => {
  const authHeaders = ServiceConfigs.getAuthorizationHeader();

  return useQuery<Array<Car>>({
    query: carBookQuery,
    variables: shouldQuery && authHeaders ? { subdomain: ServiceConfigs.getSubDomain() } : null,
    requestHeaders: { ...authHeaders },
    handler: (res: any) => res.cars,
    config: { initialData },
  });
};

export default useCarBook;
