import { isServerSide } from 'common/utils';

declare global {
  interface Window {
    dataLayer: Record<string, any>[];
    isGTMOn: boolean;
  }
}

export interface IDataLayerConfigs {
  tickle: () => void;
  scold: () => void;
  init: () => void;
  getGTMStatus: () => boolean;
  pushToDataLayer: (data: Record<string, any>) => void;
  getDataLayer: () => Record<string, any>[];
}

/**
 * Utility registry for using the GTM dataLayer
 */
const DataLayerConfigs: IDataLayerConfigs = {
  /**
   * Turns on GTM
   */
  tickle() {
    if (!isServerSide() && window) window.isGTMOn = true;
  },
  /**
   * Turns off GTM
   */
  scold() {
    if (!isServerSide() && window) window.isGTMOn = false;
  },
  /**
   * Initializes the dataLayer checking if it exists or not,
   * this should only be called when the user has a GTM ID,
   * and the GTM ID is returned successfully.
   * @function
   */
  init() {
    if (!isServerSide() && window) {
      window.isGTMOn = true;
      window.dataLayer = window.dataLayer || [];
    }
  },

  /**
   * Pushes objects to the dataLayer
   * @param data The shape of the object which is to be pushed to the dataLayer
   */
  pushToDataLayer(data) {
    if (!isServerSide() && window?.isGTMOn) window?.dataLayer.push(data);
  },

  /**
   * @returns The dataLayer array for the user
   */
  getDataLayer() {
    if (!isServerSide() && window?.isGTMOn) return window?.dataLayer;
    return [];
  },

  /**
   * @returns The the dataLayer array for the user
   */
  getGTMStatus() {
    if (!isServerSide() && window?.isGTMOn) return true;
    return false;
  },
};

export default DataLayerConfigs;
