/* eslint-disable consistent-return */
import router, { NextRouter } from 'next/router';
import { uniq } from 'ramda';

import { customHistoryType } from 'common/types';
import { RenderingRegistry } from 'common/lib/registries';
import { URLS } from '../constants';
import { isServerSide } from './misc';
import { changeRoute, replaceRoute } from './orderfast/routing';
import { SessionStorage } from './sessionStorage';

export const prefetcher = (nextRouter: NextRouter) => {
  const {
    HOME,
    SEARCH,
    BRANCHES,
    BRANCHES_DETAILS,
    ORDER_MODE,
    ORDER_DELIVERY,
    SHOPPING_CART,
    ESTIMATED_TIME,
    CATEGORY_PRODUCTS,
    PRODUCT_DETAILS,
    CHECKOUT,
    STATUS,
    ORDER_UPDATES,
    ADDRESS_BOOK,
    NEW_ADDRESS,
    EDIT_ADDRESS,
    CAR_BOOK,
    NEW_CAR,
    CAR_DETAILS,
    ADDRESS_MAP,
    AREAS_LIST,
  } = URLS;
  const urlsToBePrefetched = [HOME, SEARCH, BRANCHES, SHOPPING_CART];

  switch (nextRouter?.route) {
    case HOME:
      urlsToBePrefetched.push(CATEGORY_PRODUCTS, PRODUCT_DETAILS, ORDER_MODE, ORDER_DELIVERY, ESTIMATED_TIME);
      break;

    case CATEGORY_PRODUCTS:
      urlsToBePrefetched.push(PRODUCT_DETAILS);
      break;

    case ORDER_MODE:
    case ORDER_DELIVERY:
    case PRODUCT_DETAILS:
    case ESTIMATED_TIME:
    case SHOPPING_CART:
      // TODO check why prefetching something already should be prefetched.
      urlsToBePrefetched.push(customHistory.getPrevPath());
      break;

    case CHECKOUT:
      urlsToBePrefetched.push(ORDER_MODE, ORDER_DELIVERY, PRODUCT_DETAILS, ESTIMATED_TIME, ADDRESS_BOOK, CAR_BOOK, STATUS);
      break;

    case SEARCH:
      urlsToBePrefetched.push(customHistory.getPrevPath(), PRODUCT_DETAILS);
      break;

    case BRANCHES:
      urlsToBePrefetched.push(customHistory.getPrevPath(), BRANCHES_DETAILS);
      break;

    case ORDER_UPDATES:
    case STATUS:
      urlsToBePrefetched.push(CHECKOUT);
      break;

    case ADDRESS_BOOK:
      urlsToBePrefetched.push(CHECKOUT, NEW_ADDRESS, EDIT_ADDRESS);
      break;

    case NEW_ADDRESS:
    case EDIT_ADDRESS:
      urlsToBePrefetched.push(ADDRESS_BOOK, ADDRESS_MAP, AREAS_LIST);
      break;

    case CAR_BOOK:
      urlsToBePrefetched.push(CHECKOUT, NEW_CAR, CAR_DETAILS);
      break;

    case NEW_CAR:
    case CAR_DETAILS:
      urlsToBePrefetched.push(CAR_BOOK);
      break;

    default:
      break;
  }
  uniq(urlsToBePrefetched)
    .filter(url => url !== null)
    .map(url => nextRouter.prefetch(url));
};

let customHistoryStack = [];
const customStackHistory = 'custom-history-stack';

export const isItemPublishedInCurrentBranch = (currentBranchId, publishedBranchIds) =>
  typeof publishedBranchIds === 'string'
    ? currentBranchId === publishedBranchIds
    : publishedBranchIds?.some(id => id === currentBranchId);

export const customHistory: customHistoryType = {
  fetchCustomHistoryStack() {
    const storage = window?.sessionStorage;
    if (!storage) return;

    customHistoryStack = storage.getItem(customStackHistory) ? JSON.parse(storage.getItem(customStackHistory)) : [];
  },
  push(route) {
    const storage = window?.sessionStorage;
    if (!storage) return;

    customHistoryStack.push(route);

    storage.setItem(customStackHistory, JSON.stringify(customHistoryStack));
  },
  replace(route) {
    const storage = window?.sessionStorage;
    if (!storage) return;

    customHistoryStack.pop();
    customHistoryStack.push(route);

    storage.setItem(customStackHistory, JSON.stringify(customHistoryStack));
  },
  back() {
    const storage = window?.sessionStorage;
    if (!storage) return;

    if (!this.canRouteBack()) changeRoute(URLS.HOME);
    else {
      customHistoryStack.pop();
      router.back();
      storage.setItem(customStackHistory, JSON.stringify(customHistoryStack));
    }
  },
  getCurrPath() {
    const storage = window?.sessionStorage;
    if (!storage) return;

    const currPath = customHistoryStack[customHistoryStack.length - 1];

    return currPath;
  },
  getPrevPath() {
    if (isServerSide()) return;

    const prevPath = customHistoryStack[customHistoryStack.length - 2];

    return prevPath;
  },
  getHistoryStackLength() {
    if (isServerSide()) return;

    return customHistoryStack.length;
  },
  canRouteBack() {
    const prevPath = this.getPrevPath();
    const currPath = this.getCurrPath();

    return !(prevPath === undefined || prevPath === currPath);
  },
  handleOrderModeRouting({ branchId, manualOrderRouting }) {
    const isManualOrder = RenderingRegistry.getIsManualOrder();
    const { categoryLayout } = RenderingRegistry.getShowLayout();
    const { categoryIds, publishedBranchIds } = SessionStorage.getProductDetails() || {};
    const categoryId = categoryIds?.[0];
    const orderNumber = SessionStorage.getOrderNumber();

    if (orderNumber) {
      replaceRoute({
        pathname: URLS.SHOPPING_CART,
      });
      return;
    }

    const categoryUrl =
      categoryLayout === 1
        ? { pathname: URLS.HOME, query: { categoryId } }
        : { pathname: URLS.CATEGORY_PRODUCTS, query: { id: categoryId, notPublished: true } };

    const isComingFromProductDetails = !!categoryId;

    // if no backRoute the user will go back to product details page
    const backRoute =
      isComingFromProductDetails && !isItemPublishedInCurrentBranch(branchId, publishedBranchIds) ? categoryUrl : null;

    SessionStorage.removeProductDetails();
    if (isManualOrder && manualOrderRouting) changeRoute(manualOrderRouting);
    if (backRoute) changeRoute(categoryUrl);
    else this.back();
  },
};

export const getCurrPathValue = (): string => {
  const storage = window?.sessionStorage;
  if (!storage) return null;

  return storage.getItem('currentPath');
};
