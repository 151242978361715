export enum COUNTRY_CODES {
  KW = 'KW',
  BH = 'BH',
  EG = 'EG',
  SA = 'SA',
  AE = 'AE',
  QA = 'QA',
  OM = 'OM',
  JO = 'JO',
}
