import ServiceConfigs from 'service/config';

/**
 * prefix local storage key with the orderfast store name if its an orderfast store.
 * @param {string} key - the local storage key used to persist data.
 */
export const prefixLocalStorageKey: (key: string) => string = key => {
  if (!ServiceConfigs?.getOrderFastStore()) {
    return key;
  }
  return `${ServiceConfigs?.getSubDomain()}-${key}`;
};
