import { gql } from 'graphql-request';

const authorizeCartMutation = gql`
  mutation($subdomain: String!, $cartId: ID!) {
    authorizeCart(subdomain:$subdomain, cartId:$cartId){
      authorized
    }
  }
`;

export default authorizeCartMutation;
