import { QueryHookResponse } from '../../types/internal';
import { Country } from '../../types/generated';
import { useQuery } from '../../utils';
import { countryDetailsQuery } from '../../queries/countries';

type UseCountryDetailsType = ({ id, initialData }: { id?: Country['id']; initialData?: Country }) => QueryHookResponse<Country>;

const useCountryDetails: UseCountryDetailsType = ({ id, initialData } = {}) =>
  useQuery<Country>({
    query: countryDetailsQuery,
    variables: id ? { id } : null,
    handler: (res: any) => res?.country,
    config: { initialData },
  });

export default useCountryDetails;
