import { LANGUAGE_NOT_AVAILABLE, LANGUAGE_NOT_DEFINED, NEXT_CONFIG_DEFAULT_LOCALE } from 'common/constants';
import { ContextRegistry } from '../registries';

/**
 * Gets current localization language from url.
 *
 * @returns localization language.
 */
export const getLocalizationLanguage = () => ContextRegistry.getAppContext().router.locale;

export const isLanguageNotAvailable: (language: string) => boolean = language =>
  !language ||
  language === LANGUAGE_NOT_DEFINED ||
  language === LANGUAGE_NOT_AVAILABLE ||
  language === NEXT_CONFIG_DEFAULT_LOCALE;
