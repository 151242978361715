import { CartLinkData } from '../../types/generated';
import { QueryHookResponse } from '../../types/internal';
import { useQuery } from '../../utils';
import cartLinkDataQuery from '../../queries/cart/cartLinkData';
import ServiceConfigs from '../../config';

type UseFetchPaymentLinkDataType = ({
  referenceId,
  initialData,
}: {
  referenceId?: string;
  initialData?: CartLinkData;
}) => QueryHookResponse<CartLinkData>;

const useFetchPaymentLinkData: UseFetchPaymentLinkDataType = ({ referenceId, initialData }) =>
  useQuery<CartLinkData>({
    query: cartLinkDataQuery,
    variables: referenceId && { referenceId },
    requestHeaders: { ...ServiceConfigs.getAuthorizationHeader() },
    handler: (res: any) => res?.cartLinkData,
    config: { initialData },
  });

export default useFetchPaymentLinkData;
