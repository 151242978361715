import ServiceConfigs from 'service/config';

/**
 * Create a masked url by appending the store name if its an orderfast store.
 * @param {string} url - the url we want to mask if its an orderfast store.
 */
export const maskOrderFastUrl: (url: string) => string = url => {
  const subdomain = ServiceConfigs.getOrderFastStore();
  if (!subdomain) return url;
  return `/${subdomain}${url}`;
};
