import router from 'next/router';
import { ParsedUrlQueryInput } from 'querystring';
import { UrlObject } from 'url';
import { customHistory } from '../routesAndPrefetching';
import { maskOrderFastUrl } from './maskOrderFastUrl';

const PARAM_REGEX = /\[(.*?)\]/;

interface RouteChangeOptions {
  shallow?: boolean;
  locale?: string | false;
  scroll?: boolean;
}

/**
 * Changes the current route by pushing a new route into the stack.
 * It masks the url using the as attribute passed to router. It prepends the orderfast store slug
 * to the url shown in the browser and shows the parameters correctly if the url has
 * a dynamic route param.
 * @param {string | UrlObject} url - the url to pass to router method and mask.
 * @param {object} opts - the transition options, like locale, shallow and scroll.
 */
export const changeRoute: (url: string | UrlObject, opts?: RouteChangeOptions) => Promise<void> = async (url, opts = {}) => {
  customHistory.push(typeof url === 'string' ? url : url?.pathname);

  const asRoute = prepareUrl(url);
  await router.push(url, asRoute, { ...opts, scroll: false });
};

/**
 * Changes the current route by replacing the route in the stack.
 * It masks the url using the as attribute passed to router. It prepends the orderfast store slug
 * to the url shown in the browser and shows the parameters correctly if the url has
 * a dynamic route param.
 * @param {string | UrlObject} url - the url to pass to router method and mask.
 * @param {object} opts - the transition options, like locale, shallow and scroll.
 */
export const replaceRoute: (url: string | UrlObject, opts?: RouteChangeOptions) => Promise<void> = async (url, opts = {}) => {
  customHistory.replace(typeof url === 'string' ? url : url?.pathname);

  const asRoute = prepareUrl(url);
  await router.replace(url, asRoute, { ...opts, scroll: false });
};

/**
 * Prepend the orderfast store slug to the url and return it if its a string.
 * If the url is an object. it checks for dynamic values and shows it correctly
 * in the url. Then, prepend the store slug to the url.
 * @param {string | UrlObject} url - the url to pass to router method and mask.
 */
const prepareUrl: (url: string | UrlObject) => string | UrlObject = url => {
  if (typeof url === 'string') {
    return maskOrderFastUrl(url);
  }
  const containsDynamicValue = PARAM_REGEX.test(url.pathname);
  if (!containsDynamicValue) {
    return { ...url, pathname: maskOrderFastUrl(url.pathname) };
  }
  const asUrl = replaceDynamicValuesInUrl(url);
  return { ...asUrl, pathname: maskOrderFastUrl(asUrl.pathname) };
};

/**
 * replaces dynamic values in the pathname, like [id] with the actual id in the
 * query params object. and returns a new object with the modified pathname.
 * @param {UrlObject} url - the url to pass to router method and mask.
 */
const replaceDynamicValuesInUrl: (url: UrlObject) => UrlObject = url => {
  // TODO: use `matchAll` in case there is multiple dynamic parts in url.
  const asUrl = { ...url };
  const [toReplace, paramKey] = asUrl.pathname.match(PARAM_REGEX);
  const paramValue = asUrl.query[paramKey];
  asUrl.pathname = asUrl.pathname.replace(toReplace, paramValue);
  const { [paramKey]: _, ...newQuery } = asUrl.query as ParsedUrlQueryInput;
  asUrl.query = newQuery;
  return asUrl;
};
