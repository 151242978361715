import { ContextRegistry } from 'common/lib/registries';
import { isServerSide } from 'common/utils';

/**
 * Fallback if getStoreSlug returned null because its an orderfast domain or a manual order domain. It gets the subdomain
 * from the first section after the '/' in the url and before any query params.
 */
export const getOrderFastStore = (): string => {
  const locales = ['ar', 'en'];
  const ctx = ContextRegistry.getPageContext();
  const asPathUrl = isServerSide() ? ctx?.req.url : ctx?.asPath;
  let subdomain = asPathUrl.split('/')[1]?.split('?')[0];
  if (locales.includes(subdomain)) subdomain = ctx?.asPath.split('/')[2].split('?')[0];
  return subdomain;
};
