import { branchDetailsQuery } from '../../queries';
import { QueryHookResponse } from '../../types/internal';
import { Scalars, Store } from '../../types/generated';
import ServiceConfigs from '../../config';
import { useQuery } from '../../utils';

type UseBranchDetailsType = ({
  branchId,
  initialData,
  onComplete,
}: {
  branchId: Scalars['ID'];
  initialData?: Store;
  onComplete?: (res: any) => void;
}) => QueryHookResponse<Store>;

const useBranchDetails: UseBranchDetailsType = ({ branchId, initialData, onComplete }) =>
  useQuery<Store>({
    query: branchDetailsQuery,
    variables: branchId ? { subdomain: ServiceConfigs.getSubDomain(), branchId } : null,
    handler: (res: any) => {
      onComplete?.(res.store);
      return res.store;
    },
    config: { initialData },
  });

export default useBranchDetails;
